
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.greenSpan {
    background-color: #39a2a9;
}

.paragraphColor {
    color: #505050;
}

.mainContent {
    width: fit-content;
    @include lg {
        margin: unset;
    }
    margin: 0 auto;
}
